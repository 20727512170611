<template>
  <b-modal v-model="modal" size="lg" id="editPromotionModal" title="สร้างโปรโมชั่น">
    <b-form-group label="ชื่อโปรโมชั่น" label-for="name">
      <b-form-input v-model="form.name"></b-form-input>
    </b-form-group>
    <b-form-group label="รายละเอียด" label-for="description">
      <b-form-textarea v-model="form.description"></b-form-textarea>
    </b-form-group>

    <b-tabs pills>
      <b-tab title="โบนัส(%)" :active="!!form.bonus_percent">
        <div class="row">
          <div class="col-3">
            <b-form-group label="โบนัส(%)" label-for="bonus_percent">
              <b-form-input type="number" v-model="form.bonus_percent"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="โบนัสสูงสุด(บาท)" label-for="max_bonus">
              <b-form-input type="number" v-model="form.max_bonus"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="ฝากขั้นต่ำ" label-for="min_dep">
              <b-form-input type="number" v-model="form.min_dep"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-2">
            <b-form-group label="เทิร์น(เท่า)" label-for="turn">
              <b-form-input type="number" v-model="form.turn"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="ถอนสูงสุด(เท่า)" label-for="max_withdraw">
              <b-form-input type="number" v-model="form.max_withdraw"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-tab>
      <b-tab title="โบนัส(จำนวนเงิน)" :active="!!form.bonus_amount">
        <div class="row">
          <div class="col-3">
            <b-form-group label="โบนัส(จำนวนเงิน)" label-for="bonus_amount">
              <b-form-input type="number" v-model="form.bonus_amount"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="ฝากขั้นต่ำ" label-for="min_dep">
              <b-form-input type="number" v-model="form.min_dep"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-2">
            <b-form-group label="เทิร์น(เท่า)" label-for="turn">
              <b-form-input type="number" v-model="form.turn"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="ถอนสูงสุด(เท่า)" label-for="max_withdraw">
              <b-form-input type="number" v-model="form.max_withdraw"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="row">
      <div class="col-2">
        <b-form-group label-for="depfirst">
          <b-form-checkbox v-model="form.depfirst" :value="1" :unchecked-value="0">
            ฝากแรก
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="col-4 col-md-3">
        <b-form-group label-for="depfirst">
          <b-form-checkbox v-model="form.dep_everyday" :value="1" :unchecked-value="0">
            ฝากประจำ
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="col-2">
        <b-form-group label-for="self_pick">
          <b-form-checkbox v-model="form.self_pick" :value="1" :unchecked-value="0">
            รับโปรเอง
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="เกมที่เล่นได้" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group v-model="form.cp" :options="gamesOption"
        :aria-describedby="ariaDescribedby"></b-form-checkbox-group>
    </b-form-group>

    <div class="row">
      <div class="col-12">
        <b-form-group label-for="limit">
          <b-form-checkbox v-model="form.limit" :value="1" :unchecked-value="0">จำกัดสิทธิ</b-form-checkbox>
        </b-form-group>
      </div>
      <div v-if="form.limit === 1" class="col-3">
        <b-form-group label="ระบุจำนวนสิทธิ" label-for="amount">
          <b-form-input type="number" v-model="form.amount"></b-form-input>
        </b-form-group>
      </div>


      <div class="col-12">
        <b-form-group label="เลือกรูปภาพ" label-for="file">
          <b-form-file v-model="form.file" placeholder="เลือกไฟล์รูปภาพ..." accept="image/*"></b-form-file>
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group label="หรือแนบลิงก์รูปภาพ" label-for="img">
          <b-form-input v-model="form.img"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <!-- <b-form-group label="ลิงก์รูปภาพ" label-for="img">
      <b-form-input v-model="form.img"></b-form-input>
    </b-form-group> -->

    <template #modal-footer>
      <b-button variant="success" @click="submit()">
        ยืนยัน
      </b-button>
      <b-button variant="danger" @click="cancel()">
        ยกเลิก
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile, BTab, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormFile, BTab, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  directives: {
    Ripple
  },
  props: ['form', 'editModal'],
  computed: {
    modal: {
      get() {
        return this.editModal;
      },
      set(value) {
        this.$emit('setModal', value);
      }
    }
  },
  emits: ['submit', 'setModal'],
  data: () => ({
    // modal: this.$props.editModal,
    gamesOption: [
      { text: 'SPORT', value: 1 },
      { text: 'CASINO', value: 2 },
      { text: 'SLOT', value: 3 },
    ],
  }),
  methods: {
    submit() {
      this.$emit('submit', this.$props.form)
      this.$emit('setModal', false)
      this.clearForm();
    },
    cancel() {
      this.$emit('setModal', false);
      this.clearForm();
    },
    clearForm() {
      this.form = {
        name: '',
        description: '',
        percent: '',
        min_dep: '',
        turn: '',
        min_bonus: '',
        max_bonus: '',
        min_withdraw: '',
        max_withdraw: '',
        depfirst: 0,
        depany: '',
        canplay: [1, 2, 3],
        cp: '',
        img: '',
        limit: '',
        amount: '',
        file: null
      }
    }
  }
}
</script>